.homepage {
  text-align: center;
}

.homepage-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Avent  */

.avent-page {
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(170px, auto);
}

.avent-cell {
  font-weight: bold;
  text-align: center;
  border: 1px solid black;
  line-height: 170px;
  font-size: 50px;
}

.password-page {
  align-items: center;
  display: flex;
  justify-content: center;
}
.password-form {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: 200px;
}

.hover-target:hover .show-on-hover {
  display: block;
}

.show-on-hover {
  display: none;
}

.hover-target:hover .hide-on-hover {
  display: none;
}

.hide-on-hover {
  display: block;
}

.today {
  background: #d4423e;
}

.open {
  border: 2px solid #095228 !important;
}

/* .closed {
} */

.avent-text {
  font-size: 24px;
  line-height: 36px;
}

/* Sport Timer  */

.sport-timer-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #738091;
  min-height: 100vh;
}
.sport-timer-form {
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 200px;
  padding: 10px;
  background-color: #C5CBD3;
}

.sport-timer-form-button {
  margin-top: 20px;
  width: 100px;
}

.sport-timer-status {
  width: 380px;
}

.sport-timer-status h1 {
  font-size: 70pt;
}

.sport-timer-status h1 {
  font-size: 50pt;
}

/* Trains  */

.date-links-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #738091; /* Same background as the timer page */
  min-height: 100vh;
}

.date-links-form {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  padding: 10px;
  background-color: #C5CBD3; /* Matching form background */
}

.date-picker-container {
  margin-top: 15px;
}

.date-links-form label {
  margin-bottom: 5px;
  font-size: 14pt;
  color: #333;
}

.date-links-list {
  margin-top: 30px;
  list-style-type: none;
  padding: 0;
}

.date-links-list li {
  margin: 5px 0;
  font-size: 14pt;
}

.date-links-list a {
  text-decoration: none;
  color: #D3D8DE;
}

.date-links-list a:hover {
  text-decoration: underline;
}

.button-container {
  margin-top: 20px;
}

.date-links-form-button {
  margin-top: 20px;
  width: 100px;
}
